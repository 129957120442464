export const columns = [
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    align: 'center',
    // ellipsis:true,
    fixed: 'left',
    scopedSlots: {customRender: 'name'}
  },
  {
    title: '商品图片',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    width:100,
    ellipsis: true,
    align: 'center',
    scopedSlots: {customRender: 'imgUrl'}
  },
  {
    title: '标签',
    dataIndex: 'tag',
    key: 'tag',
    width: 100,
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'name'}
  },
  {
    title: '分类',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: 120,
    align: 'center',
    scopedSlots: {customRender: 'categoryName'}
  },
  {
    title: '品牌',
    dataIndex: 'brandName',
    key: 'brandName',
    width:100,
    align: 'center',
  },
  {
    title: '型号',
    dataIndex: 'barCode',
    key: 'barCode',
    width:150,
    align: 'center',
    // scopedSlots: {customRender: 'price'}
  },
  {
    title: '物料编码',
    dataIndex: 'goodsCode',
    key: 'goodsCode',
    width:150,
    align: 'center',
    // scopedSlots: {customRender: 'price'}
  },
  {
    title: '库存',
    dataIndex: 'stock',
    key: 'stock',
    /*width:150,*/
    width:100,
    align: 'center',
    ellipsis: true
  },
  {
    title: '审核状态',
    dataIndex: 'flowStatus',
    key: 'flowStatus',
    /*width:150,*/
    width:100,
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'flowStatus'}
  },
  {
    title: '标准开票价（D价）',
    dataIndex: 'sellPrice',
    key: 'sellPrice',
    width:150,
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'price'}
  },
  {
    title: '上架状态',
    dataIndex: 'flagUp',
    key: 'flagUp',
    /*width:150,*/
    width:100,
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'flagUp'}
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: 200,
    align: 'center',
    ellipsis: true
  },
  {
    title: '添加时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    align: 'center',
    ellipsis: true
  },
  // {
  //     title: '操作',
  //     dataIndex: 'action',
  //     key: 'action',
  //     width:150,
  //     align:'center',
  //     ellipsis:true,
  //     scopedSlots: { customRender: 'action'}
  // },
]


export const Repertorcolumns = [
  {
    title: '商品图片',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    width: 150,
    align: 'center',
    scopedSlots: {customRender: 'imgUrl'}
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    // width: 300,
    // fixed: 'left',
    scopedSlots: {customRender: 'name'}
  },
  {
    title: '分类',
    dataIndex: 'categoryName',
    key: 'categoryName',
    /*width:150,*/
    width: 150,
    align: 'center',
  },
  {
    title: '品牌',
    dataIndex: 'brandName',
    key: 'brandName',
    /*width:150,*/
    width: 150,
    align: 'center',
  },
  {
    title: '规格型号',
    dataIndex: 'specStr',
    key: 'specStr',
    /*width:150,*/
    width: 150,
    align: 'center',
  },
  {
    title: '单位',
    dataIndex: 'proUnitName',
    key: 'proUnitName',
    width: 80,
    align: 'center',
  },
  {
    title: '订单占用库存',
    dataIndex: 'orderOccupyRep',
    key: 'orderOccupyRep',
    /*width:150,*/
    width: 100,
    align: 'center',
  },
  {
    title: '实际库存',
    dataIndex: 'realQuantity',
    key: 'realQuantity',
    /*width:150,*/
    width: 100,
    align: 'center',
  },
  {
    title: '销售库存',
    dataIndex: 'saleQuantity',
    key: 'saleQuantity',
    /*width:150,*/
    width: 100,
    align: 'center',
  },
]