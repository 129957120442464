<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-16 14:35:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 15:46:54
-->
<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <a-row>
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="2">
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="商品名称">
                <a-input v-model.trim="searchData.name" allowClear placeholder="请输入商品名称" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="型号">
                <a-input v-model.trim="searchData.model" allowClear placeholder="请输入商品型号" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="物料编码">
                <a-input v-model.trim="searchData.goodsCode" allowClear placeholder="请输入物料编码" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="分类">
                <a-select placeholder="请选择分类" allowClear v-model="searchData.categoryId">
                  <a-select-option :value="item.id" v-for="(item, index) in categoryList" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="品牌">
                <a-select placeholder="请选择品牌" allowClear v-model="searchData.brandId">
                  <a-select-option :value="item.id" v-for="(item, index) in brandList" :key="index">{{
                    item.chName
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12" v-if="pageType == 'all'">
              <a-form-model-item label="审核状态">
                <DictSelect
                  field="productStatus"
                  :value.sync="searchData.flowStatus"
                  style="width: 100%"
                  placeholder="审核状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12" v-if="pageType == 'audited'">
              <a-form-model-item label="审核状态">
                <DictSelect
                  field="auditedProductStatus"
                  :value.sync="searchData.flowStatus"
                  style="width: 100%"
                  placeholder="审核状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12" v-if="pageType != 'putaway' || pageType != 'off_shelf'">
              <a-form-model-item label="上架状态">
                <DictSelect
                  field="groundingStatus"
                  :value.sync="searchData.flagUp"
                  style="width: 100%"
                  placeholder="状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div v-if="pageType == 'all' || pageType == 'untreated'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <a-button
            v-if="(selectedRows.length > 0 && selectedRows[0].flowStatus == 1 && isSubmit)
            "
            type="primary"
            @click="batchSubmitAudit"
            icon="form"
            >提交审核</a-button
          >
          <a-button
            v-if="selectedRows.length == 1 && selectedRows[0].flowStatus == 2 && isAudit && selectedRows[0].taskId != null"
            type="primary"
            @click="visible = true"
            icon="form"
            >审核</a-button
          >
          <a-button
            v-if="selectedRows.length > 1 && selectedRows[0].flowStatus == 2 && isAudit && selectedRows[0].taskId != null"
            type="primary"
            @click="toHandlerAll"
            >批量审核</a-button
          >
          <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isAdd">新增</a-button>
          <a-button type="primary" @click="toHandler('edit')" icon="edit" v-if="isEdit">编辑</a-button>
          <!-- <a-button v-if="selectedRowKeys.length==0||(selectedRows.length>0)" type="primary" @click="showTrackLog()" icon="eye">跟踪日志</a-button> -->
          <a-button
            v-if="selectedRows.length > 0 && selectedRows[0].flowStatus == 2 && isAudit"
            type="default"
            @click="toHandler('rollbackStatus')"
            icon="rollback"
            >撤审</a-button
          >
          <a-button type="default" @click="toHandler('del')" icon="delete" v-if="isDelete">删除</a-button>
          <downLoad
            method="post"
            api="/api/product/system/info/excelExportProductList"
            :params="getExport"
            v-if="isExport"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            name="商品导出.xls"
          >商品导出</downLoad>
          <a-upload
            name="file"
            :action="uploadUrl"
            :headers="headers"
            :showUploadList="false"
            @change="uploadChange"
            v-if="isImport"
          >
            <a-button icon="upload" type="default">商品导入</a-button>
          </a-upload>
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 2 && selectedRows[0].flowStatus == 3 && isPutaway)
            "
            type="default"
            @click="onStatusChange('up')"
            icon="check-circle"
            >上架</a-button
          >
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 1 && selectedRows[0].flowStatus == 3 && isSoldOut)
            "
            type="default"
            @click="onStatusChange('down')"
            icon="stop"
            >下架</a-button
          >
          <a-button type="default" icon="download"  @click="downLoadTemp">
            <slot>下载导入模版</slot>
          </a-button>

        </div>
        <div v-if="pageType == 'audited'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <downLoad
            method="post"
            api="/api/product/system/info/excelExportProductList"
            :params="getExport"
            v-if="isExport"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            name="商品导出.xls"
          >商品导出</downLoad>
          <a-upload
            name="file"
            :action="uploadUrl"
            :headers="headers"
            :showUploadList="false"
            @change="uploadChange"
            v-if="isImport"
          >
            <a-button icon="upload" type="default">商品导入</a-button>
          </a-upload>
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 2 && selectedRows[0].flowStatus == 3 && isPutaway)
            "
            type="default"
            @click="onStatusChange('up')"
            icon="check-circle"
            >上架</a-button
          >
        </div>
        <div v-if="pageType == 'off_shelf'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <downLoad
            method="post"
            api="/api/product/system/info/excelExportProductList"
            :params="getExport"
            v-if="isExport"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            name="商品导出.xls"
          >商品导出</downLoad>
          <a-upload
            name="file"
            :action="uploadUrl"
            :headers="headers"
            :showUploadList="false"
            @change="uploadChange"
            v-if="isImport"
          >
            <a-button icon="upload" type="default">商品导入</a-button>
          </a-upload>
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 2 && selectedRows[0].flowStatus == 3 && isPutaway)
            "
            type="default"
            @click="onStatusChange('up')"
            icon="check-circle"
            >上架</a-button
          >
          <a-button type="default" @click="toHandler('del')" icon="delete" v-if="isDelete">删除</a-button>
        </div>
        <div v-if="pageType == 'pending_review'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <a-button
            v-if="selectedRows.length == 1 && selectedRows[0].flowStatus == 2 && isAudit && selectedRows[0].taskId != null"
            type="primary"
            @click="visible = true"
            icon="form"
            >审核</a-button
          >
          <a-button
            v-if="selectedRows.length > 1 && selectedRows[0].flowStatus == 2 && isAudit && selectedRows[0].taskId != null"
            type="primary"
            @click="toHandlerAll"
            >批量审核</a-button
          >
          <a-button
            v-if="selectedRows.length > 0 && selectedRows[0].flowStatus == 2 && isAudit"
            type="default"
            @click="toHandler('rollbackStatus')"
            icon="rollback"
            >撤审</a-button
          >
          <downLoad
            method="post"
            api="/api/product/system/info/excelExportProductList"
            :params="getExport"
            v-if="isExport"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            name="商品导出.xls"
          >商品导出</downLoad>
          <a-upload
            name="file"
            :action="uploadUrl"
            :headers="headers"
            :showUploadList="false"
            @change="uploadChange"
            v-if="isImport"
          >
            <a-button icon="upload" type="default">商品导入</a-button>
          </a-upload>
        </div>
        <div v-if="pageType == 'putaway'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <downLoad
            method="post"
            api="/api/product/system/info/excelExportProductList"
            :params="getExport"
            v-if="isExport"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            name="商品导出.xls"
          >商品导出</downLoad>
          <a-upload
            name="file"
            :action="uploadUrl"
            :headers="headers"
            :showUploadList="false"
            @change="uploadChange"
            v-if="isImport"
          >
            <a-button icon="upload" type="default">商品导入</a-button>
          </a-upload>

          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 1 && selectedRows[0].flowStatus == 3 && isSoldOut)
            "
            type="default"
            @click="onStatusChange('down')"
            icon="stop"
            >下架</a-button
          >
        </div>

        <a-row :gutter="8">
          <a-col :span="4">
            <div style="border: 1px solid #e8e8e8; background: white; height: 472px; overflow-y: auto">
              <a-tree
                :treeData="dataList"
                v-if="dataList.length > 0"
                :load-data="onLoadData"
                :expanded-keys="expandedKeys"
                :auto-expand-parent="autoExpandParent"
                @expand="onExpand"
                @select="handleSelect"
              >
              </a-tree>
            </div>
          </a-col>
          <a-col :span="20" v-if="pageType != 'pending_review' && pageType != 'all'">
            <!-- 数据表格 -->
            <a-table
              :loading="tableLoading"
              :row-selection="rowSelection"
              :rowKey="(record) => record.id"
              :columns="columns"
              :data-source="tableData"
              bordered
              :pagination="page"
              @change="changeTable"
              :customRow="changeTableRow"
              :rowClassName="rowClassName"
              :scroll="{ x: 1300 }"
            >
              <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
              <span slot="name" slot-scope="text">
                <a-tooltip>
                  <template slot="title"> {{ text }} </template> {{ text }}
                </a-tooltip>
              </span>
              <span slot="imgUrl" slot-scope="item">
                <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
              </span>
              <span slot="flagUp" slot-scope="item">
                <!-- {{item == 1 ? '上架' : item == 2 ? '下架' : '强制下架'}} -->
                <a-tag v-if="item == 1" color="#67c23a">上架</a-tag>
                <a-tag v-if="item == 2" color="#909399">下架</a-tag>
                <a-tag v-if="item == 3" color="#909399">强制下架</a-tag>
              </span>
              <span slot="parentCategoryName" slot-scope="text">
                <a-tooltip>
                  <template slot="title"> {{ text }} </template> {{ text }}
                </a-tooltip>
              </span>
              <span slot="flowStatus" slot-scope="text">
                <a-tag v-if="text == 1" color="#e6a23c">未处理</a-tag>
                <a-tag v-if="text == 2" color="#409eff">待审核</a-tag>
                <a-tag v-if="text == 3" color="#67c23a">审核通过</a-tag>
                <a-tag v-if="text == 4" color="#f5222d">已拒绝</a-tag>
              </span>
              <span slot="price" slot-scope="item">{{ item | formatMoney(item) }}</span>
              <span slot="action" slot-scope="text, row">
                <a @click="handleEdit(row)">编辑</a>
                <a-divider type="vertical" />
                <!-- <a @click="delByIds([row.id])">删除</a>
                        <a-divider type="vertical" /> -->
                <a class="ant-dropdown-link">更多<a-icon type="down" /></a>
              </span>
            </a-table>
          </a-col>
          <a-col :span="20" v-if="pageType == 'pending_review' || pageType == 'all'">
            <!-- 数据表格 -->
            <a-table
              :loading="tableLoading"
              :row-selection="rowSelectionAll"
              :rowKey="(record) => record.goodsId"
              :columns="columns"
              :data-source="tableData"
              bordered
              :pagination="page"
              @change="changeTable"
              :customRow="changeTableRow"
              :rowClassName="rowClassName"
              :scroll="{ x: 1300 }"
            >
              <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
              <span slot="name" slot-scope="text">
                <a-tooltip>
                  <template slot="title"> {{ text }} </template> {{ text }}
                </a-tooltip>
              </span>
              <span slot="imgUrl" slot-scope="item">
                <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
              </span>
              <span slot="flagUp" slot-scope="item">
                <!-- {{item == 1 ? '上架' : item == 2 ? '下架' : '强制下架'}} -->
                <a-tag v-if="item == 1" color="#67c23a">上架</a-tag>
                <a-tag v-if="item == 2" color="#909399">下架</a-tag>
                <a-tag v-if="item == 3" color="#909399">强制下架</a-tag>
              </span>
              <span slot="parentCategoryName" slot-scope="text">
                <a-tooltip>
                  <template slot="title"> {{ text }} </template> {{ text }}
                </a-tooltip>
              </span>
              <span slot="flowStatus" slot-scope="text">
                <a-tag v-if="text == 1" color="#e6a23c">未处理</a-tag>
                <a-tag v-if="text == 2" color="#409eff">待审核</a-tag>
                <a-tag v-if="text == 3" color="#67c23a">审核通过</a-tag>
                <a-tag v-if="text == 4" color="#f5222d">已拒绝</a-tag>
              </span>
              <span slot="price" slot-scope="item">{{ item | formatMoney(item) }}</span>
              <span slot="action" slot-scope="text, row">
                <a @click="handleEdit(row)">编辑</a>
                <a-divider type="vertical" />
                <!-- <a @click="delByIds([row.id])">删除</a>
                        <a-divider type="vertical" /> -->
                <a class="ant-dropdown-link">更多<a-icon type="down" /></a>
              </span>
            </a-table>
          </a-col>
        </a-row>
      </a-row>
      <!-- 编辑表单 -->
      <ProductGoods-edit-modal ref="ProductGoodsEditModal" @reload="getData"></ProductGoods-edit-modal>
      <!-- 查看表单 -->
      <ProductGoods-info-modal ref="ProductGoodsInfoModal" @reload="getData"></ProductGoods-info-modal>
      <SelectTrackLogModal ref="SelectTrackLogModal"></SelectTrackLogModal>
      <a-modal
        title="审核"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
      >
        <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusRadio" @change="onGoodStatusChange">
              <a-radio :value="3"> 通过 </a-radio>
              <a-radio :value="4"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
<!--          <a-form-model-item label="审核后上架">-->
<!--            <a-switch v-model="obj.flagAutoUp" checked-children="启用" un-checked-children="禁用" default-checked />-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="原因">
            <a-textarea v-model="obj.rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">取消</a-button>
          <a-button type="danger"  @click="onAuditAll('refuse')" v-if="statusRadio == 4">确定</a-button>
          <a-button type="primary" @click="onAuditAll('audit')" v-if="statusRadio == 3">确定</a-button>
        </div>
      </a-modal>
      <a-modal
        title="批量审核"
        width="40%"
        :visible="visibleAll"
        :footer="null"
        @cancel="visibleAll = false"
      >
        <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusRadioAll" @change="onGoodStatusChange">
              <a-radio :value="3"> 通过 </a-radio>
              <a-radio :value="4"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
<!--          <a-form-model-item label="审核后上架">-->
<!--            <a-switch v-model="flagAutoUpAll" checked-children="启用" un-checked-children="禁用" default-checked />-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="原因">
            <a-textarea v-model="rejectReasonAll"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="handleCanceAll">取消</a-button>
          <a-button type="danger" :loading="loading" @click="handleOkAll">确定</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
  </a-spin>
</template>

<script>
import { columns } from '@/views/product_goods/components/colums.js'
import ProductGoodsEditModal from '@/views/product_goods/components/ProductGoodsEditModal.vue'
import ProductGoodsInfoModal from '@/views/product_goods/components/ProductGoodsInfoModal.vue'
import { delProductGoods, rollbackStatus } from '@/views/product_goods/api/ProductGoodsApi'
import SelectTrackLogModal from '@/views/product_goods/components/SelectTrackLogModal.vue'
import { checkPermission } from '@/utils/permissions'
import { baseURL } from '@/utils/request'
import {auditTask, auditTaskBatch} from '@/api/activiti';
import {PRODUCT_TEMP} from '@/utils/downloadTempHref';
const qs = require('qs')

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'productGoods',
  components: {
    ProductGoodsEditModal,
    SelectTrackLogModal,
    ProductGoodsInfoModal,
  },
  data() {
    return {
      confirmLoading: false,
      visible: false,
      spinning:false,
      obj: {
        flagAutoUp: true,
        rejectReason: '',
      },
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      autoExpandParent: true,
      expandedKeys: [0],
      dataList: [],
      dataListAll: [],
      statusRadioAll: 3,
      flagAutoUpAll: true,
      rejectReasonAll: '',
      visibleAll: false,
      categoryId: 1,
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/product/system/info/excelProductReader',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      isEdit: checkPermission('product:goods:edit'),
      isAdd: checkPermission('product:goods:add'),
      isDelete: checkPermission('product:goods:delete'),
      isCheck: checkPermission('product:goods:check'),
      isImport: checkPermission('product:goods:import'),
      isExport: checkPermission('product:goods:export'),
      isAudit: checkPermission('product:goods:audit'),
      isSubmit: checkPermission('product:goods:submit'),
      isPutaway: checkPermission('product:goods:putaway'),
      isSoldOut: checkPermission('product:goods:sold_out'),
      categoryList: [],
      brandList: [],
      statusRadio: 3,
      loading:false ,
      downloadTempHref: PRODUCT_TEMP,
    }
  },

  props: {
    flagDealer: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: String,
      default: '',
    },
    status: {
      type: Array,
      default: [],
    },
    flagUp: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    rowSelectionAll() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          console.log("_this.selectedRowKeys",_this.selectedRowKeys)
        },
      }
    },
    getExport(){
      let obj = {
        ...this.searchData,
        categoryId:this.categoryId,
        ids:this.selectedRowKeys
      }

      return obj
    }
  },
  methods: {
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    downLoadTemp (){
      window.open(this.downloadTempHref, '_blank')
    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.flowStatus === 2 && item.taskId != null
      })
      if (everyResult) {
        this.visibleAll = true
        this.dataListAll = this.selectedRows
      } else {
        this.$notification.error({ message: '请选择相同待审核记录' })
      }
    },
    // 现替换成审批流方法
    // handleOkAll() {
    //   this.confirmLoading = true
    //   let params = {
    //     flagAutoUp: this.flagAutoUpAll,
    //     authStatus: this.statusRadioAll,
    //     verifyRemark: this.rejectReasonAll,
    //   }
    //   params.ids = this.dataListAll.map((item) => item.id)
    //   console.log(params)
    //   this.axios
    //     .post('/api/product/system/info/auditStatusList', params)
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.$message.success(res.message)
    //         this.visibleAll = false
    //         this.getData()
    //       }
    //     })
    //     .finally(() => {
    //       this.confirmLoading = false
    //     })
    // },

    handleOkAll() {
      const _this = this
      let result = 2
      if (_this.statusRadioAll == 4) {
        result = 3
      }
      _this.loading = true
      auditTaskBatch({ taskIds: _this.dataListAll.map((item) => item.taskId), result: result ,comment: _this.rejectReasonAll }).then((res) => {
        if (res.code == 200) {
          _this.$message.success('审核成功')
          _this.visibleAll = false
          _this.getData()
        }else {
          _this.$message.error(res.message)
        }
        _this.visibleAll = false
      })
      .finally(() => {
        _this.loading = false
      })

    },
    handleCanceAll() {
      this.loading = false
      this.visibleAll = false
      this.flagAutoUpAll = true
      this.statusRadioAll = ''
      this.rejectReasonAll = ''
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'

      if (record.flagUp != 1) className += ' font-red'

      return className
    },

    //导入
    uploadChange (info) {
      this.tableLoading = true
      setTimeout(()=>{
        if(info.file.response){
          let hint = info.file.response
          if(hint.code === 200) {
            this.tableLoading = false
            this.$message.success(hint.message)
            this.getData()
          }else if(hint.code === 500) {
            this.tableLoading = false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })

    },
    // uploadChange(info) {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList)
    //   }
    // },

    // 审核
    // 审核，现替换成审批流方法
    onAudit(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      let query = {}
      if (name == 'sbm_audit') {//提交审核
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 2,
          rejectReason: _this.obj.rejectReason,
          flagAutoUp: _this.obj.flagAutoUp,
        }
      } else if (name == 'audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 3,
          rejectReason: _this.obj.rejectReason,
          flagAutoUp: _this.obj.flagAutoUp,
        }
      } else if (name == 'refuse') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 4,
          rejectReason: _this.obj.rejectReason,
          flagAutoUp: _this.obj.flagAutoUp,
        }
      }
      _this.axios.post('/api/product/system/info/auditStatus', query).then((res) => {
        if (res.code == 200) {
          _this.$notification.success({ message: res.message })
          _this.getData()
          _this.obj = {}
          _this.visible = false
        } else {
          _this.$notification.error({ message: res.message })
          _this.getData()
          _this.obj = {}
        }
      })
    },

    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this

      const everyResult = this.selectedRows.every((item) => {
        return item.flowStatus === 1
      })
      if (everyResult) {
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.tableLoading  = true
            const query = {
              ids : _this.selectedRows.map((item) => item.id)
            }
            _this.axios.post('/api/product/system/info/batchSubmitAudit', query).then(res => {
              if(res.code == 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              }else {
                _this.$notification.error({ message: res.message })
                _this.getData()
              }

            })
          },
          onCancel() {
          }
        })
      }else{
        this.$notification.error({ message: '请选择未提交审核记录' })
      }
    },

    // 审核
    onAuditAll(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      let result = 2
      if (name == 'refuse') {
        result = 3
      }
      const query = {
        taskId: _this.selectedRows[0].taskId,
        result: result,
        comment: _this.obj.rejectReason != null ? _this.obj.rejectReason : ''
      }
      auditTask(query).then((res) => {
        if (res.code === 200) {
          _this.$message.success('审核成功')
          _this.getData()
          _this.obj = {}
          _this.visible = false
        } else {
          _this.$notification.error({ message: res.message })
          _this.getData()
          _this.obj = {}
        }
      })
    },

    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.ProductGoodsEditModal.setRowData(item, 'check')
    },

    /**
     * 获取表格数据
     */
    getData(num) {
      if(num != 1){
         this.selectedRows = []
         this.selectedRowKeys = []
      }
      this.tableLoading = true
      let obj = {
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        categoryId: this.categoryId == 1 ? '' : this.categoryId,
        flowStatusList: this.status,
        flagUp: this.flagUp != 0 ? this.flagUp : '',
        flagDealer: this.flagDealer,
      }
      const postData = Object.assign(obj, this.searchData)
      this.axios
        .get(`/api/product/system/info/list?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.axios.get('/api/product/category/parentCategoryList').then((res) => {
        this.categoryList = res.body
      })
      this.axios.get('/api/product/system/brand/list').then((res) => {
        this.brandList = res.body.records
      })
    },

    onGoodStatusChange(val) {
      this.statusRadio = val.target.value
      this.statusRadioAll = val.target.value
    },

    // 修改是否上架
    onStatusChange(name) {
      const that = this
      if (that.selectedRows.length === 0) {
        that.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      let nameMessage = name == 'up' ?'上架':'下架' ;
      that.$confirm({
        title: nameMessage + '警告',
        content: '确认要' + nameMessage + '吗?',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          let num = {}
          if (name == 'up') {
            num = {
              id: that.selectedRows[0].id,
              flagUp: 1,
            }
          } else if (name == 'down') {
            num = {
              id: that.selectedRows[0].id,
              flagUp: 2,
            }
          }
          that.axios.post('/api/product/system/info/editFlagUpStatus', num).then((res) => {
            that.$notification.success({ message: res.message })
            that.getData()
          })
        },
        onCancel() {},
      })

      // if (name == 'up') {
      //   num = {
      //     id: that.selectedRows[0].id,
      //     flagUp: 1,
      //   }
      // } else if (name == 'down') {
      //   num = {
      //     id: that.selectedRows[0].id,
      //     flagUp: 2,
      //   }
      // }
      // that.axios.post('/api/product/system/info/editFlagUpStatus', num).then((res) => {
      //   that.$notification.success({ message: res.message })
      //   that.getData()
      // })
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.getData()
    },

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData(1)
    },

    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.goodsId) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.goodsId]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    // 编辑
    handleEdit(row) {
      const _this = this
      _this.$refs.ProductGoodsEditModal.setRowData(row, 'edit')
    },

    // 删除
    delByIds(id) {
      const _this = this
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          const res = await delProductGoods(_this.selectedRows[0].id)
          if (res.code === 200) {
            _this.$notification.success({ message: res.message })
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
        },
        onCancel() {},
      })
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.ProductGoodsEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          if (_this.selectedRows[0].flagUp != 2) {
            _this.$notification.info({
              message: '请先下架该商品，再进行编辑',
            })
            return
          }
          _this.$refs.ProductGoodsEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delProductGoods(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.ProductGoodsInfoModal.setRowData(_this.selectedRows[0], 'check')
          break
      }
    },

    showTrackLog() {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.SelectTrackLogModal.show(_this.selectedRowKeys[0])
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    handleSelect(value) {
      let id = Number(value.length > 0 ? value.toString() : 1)
      this.categoryId = id
      this.getData()
    },
    onLoadData(treeNode) {
      let id = treeNode.eventKey
      return new Promise(
        (resolve) => {
          this.axios.get('/api/product/category/list/' + id + '/1').then((res) => {
            treeNode.dataRef.children = res.body
            this.dataList = [...this.dataList]
          })
          resolve()
        },
        (reject) => {}
      )
    },
  },
  created() {
    this.getData()
    this.axios.get('/api/product/category/list/0/1').then((res) => {
      this.dataList = res.body  || []
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
::v-deep .ant-table-tbody > .font-red {
  color: red !important;
}
</style>
